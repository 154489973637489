<!-- eslint-disable vue/no-mutating-props -->
<script setup lang="ts">
const props = defineProps<{ isOpen: boolean }>();

defineEmits<{
  (e: "close"): void;
}>();

const { trackCustomEvent } = useGtmTracking();
trackCustomEvent("WebshopModal");
</script>

<template>
  <div>
    <UModal v-model="props.isOpen" prevent-close>
      <div class="relative p-8" style="background: linear-gradient(0deg, #F3F1EC 0%, #F3F1EC 100%), #F8F7F4;">
        <UIcon name="i-ph-x" class="absolute right-2 top-2 cursor-pointer" @click="$emit('close')" />
        <HeaderWebshopLinks />
      </div>
    </UModal>
  </div>
</template>

<style scoped>
</style>
